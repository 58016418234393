var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v7.36.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/lisa";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

Sentry.init({
  debug: false,

  denyUrls: [
    // from HubSpot script, cfr
    //  * https://community.hubspot.com/t5/CMS-Development/Next-js-Project-utilizing-Hubspot-forms-is-throwing-this/td-p/741262
    //  * https://community.hubspot.com/t5/CMS-Development/LeadFlow-Errors/m-p/381841
    /app:\/\/\/leadflows\.js/i,
  ],
  dsn: SENTRY_DSN || 'https://0c6a224a0b83b07bbe6600ecbfd9a2a2@o216259.ingest.us.sentry.io/4507934647386112',

  environment: SENTRY_ENVIRONMENT,

  tracesSampleRate: 0.01,
})
