import { Characteristics } from '@/types/cate-graphql-generated-types'

export type LisaServices =
  | 'fomo'
  | 'visits'
  | 'casavoAncillaryServicesOffered'
  | 'consentsAgencyForm'
  | 'consentsCasavoForm'
  | 'sellerFlowLandingPage'
  | 'POIs'
  | 'saveSearch'
  | 'zoneDescription'
  | 'mortgage'
  | 'prerenderedSeoLinks'
  | 'unsubscribe'
  | 'filteredPages'
  | 'obfuscateExactLocation'
  | 'searchByArea'
  | 'affordability'
  | 'mortgageValuation'

export const isLocaleEnabledFor = (service: LisaServices, locale: Locale): boolean => {
  const localesForServices: Record<LisaServices, Locale[]> = {
    POIs: ['it', 'en'],
    affordability: ['it', 'en'],
    casavoAncillaryServicesOffered: ['it', 'en'],
    consentsAgencyForm: ['it', 'en'],
    consentsCasavoForm: ['it', 'en'],
    filteredPages: ['it', 'en'],
    fomo: ['it', 'en'],

    mortgage: ['it', 'en'],
    mortgageValuation: ['it'],
    // Proprioo agents want to "obfuscate" the exact location of the property because
    // they do not have an exclusive mandate. As temporary solution we can avoid to zoom
    // too much on the map for France. But it's a temporary hack we hope di kill ASAP
    obfuscateExactLocation: ['fr'],
    prerenderedSeoLinks: ['it', 'en'],
    saveSearch: ['it', 'en'],
    searchByArea: ['it', 'en'],
    sellerFlowLandingPage: ['it'],
    unsubscribe: ['it', 'en'],
    visits: ['it', 'en'],
    zoneDescription: ['it', 'en'],
  }

  return localesForServices[service].includes(locale)
}

/** Maps locales to their respective country filter for Graphql queries */
export const countryFiltersByLocale: Record<Locale, CateCountry> = {
  en: 'italy',
  fr: 'france',
  it: 'italy',
}

export const localesByCountryName: Record<CateCountry, Locale[]> = {
  france: ['fr'],
  italy: ['en', 'it'],
}

export const localeByCountry: Record<CateCountry, Locale> = {
  france: 'fr',
  italy: 'it',
}

export const defaultCitiesByLocale: Record<Locale, string> = {
  en: 'it/milano',
  fr: 'fr/paris',
  it: 'it/milano',
}

export const getLocaleByCountryFilter = (country: CateCountry): Locale => {
  return localeByCountry[country] || 'it'
}

export const getCountryFilterByLocale = (locale: Locale): CateCountry => {
  return countryFiltersByLocale[locale] || 'italy'
}

/** returns the number of rooms and the label to display based on current locale (some locales count bedrooms, other the total number of rooms) */
export const getRoomDataForLocale = (locale: Locale, characteristics: Characteristics) => {
  return {
    numberOfRooms: characteristics.rooms,
    roomLabel: 'rooms',
  }
}

export const localesByTotalRoomsCounter: Record<string, Locale[]> = {
  bedrooms: [],
  rooms: ['it', 'en', 'fr'],
}

const citiesInItaly = [
  'milano',
  'sesto-san-giovanni',
  'roma',
  'torino',
  'firenze',
  'bologna',
  'scandicci',
  'collegno',
  'rivoli',
  'grugliasco',
  'verona',
  'san-lazzaro-di-savena',
  'casalecchio-di-reno',
  'bagno-a-ripoli',
  'cologno-monzese',
  'san-donato-milanese',
  'corsico',
  'imola',
  'campi-bisenzio',
  'sesto-fiorentino',
  'monza',
  'parabiago',
  'anzio',
  'nettuno',
  'pomezia',
  'fiumicino',
  'ardea',
  'cerveteri',
  'desio',
  'san-giuliano-milanese',
  'legnano',
  'rho',
  'cinisello-balsamo',
  'moncalieri',
  'nichelino',
  'paderno-dugnano',
  'peschiera-borromeo',
  'segrate',
  'ciampino',
  'frascati',
  'grottaferrata',
  'marino',
  'ladispoli',
  'santa-marinella',
  'manziana',
  'varese',
  'porto-ceresio',
  'como',
  'arcisate',
  'guidonia-montecelio',
]

/* FIXME we should create this starting from src/page-commons/active-cities-static.json
/** Provides the list of cities (as strings) available for each locale, to be used in next.JS data-fetching methods
 */
export const citiesByLocale: Record<Locale, string[]> = {
  en: citiesInItaly,
  fr: ['paris'],
  it: citiesInItaly,
}
